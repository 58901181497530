import React from "react";
import { GetServerSideProps } from "next";
import { useSession, getSession } from "next-auth/client";
import { useRouter } from "next/router";

import Header from "components/Header";
import Spinner from "components/Spinner";

export default function Index() {
  const [query, setQuery] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [date, setDate] = React.useState("");
  const [session, loading] = useSession();

  const router = useRouter();

  const formSubmit = (event: any) => {
    event.preventDefault();
    if (query.trim() || author.trim() || date.trim()) {
      router.push({
        pathname: "/search",
        query: {
          query: query,
          author: author,
          date: date,
        },
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!session) {
    router.push("/login");
    return null;
  }

  return (
    <div>
      <Header />
      <div className="row mt-4">
        <div className="col-md-6 offset-md-3">
          <form>
            <div className="mb-3">
              <input
                type="text"
                autoFocus
                autoComplete="off"
                className="form-control shadow-sm"
                id="query"
                placeholder="Search by title, article or pixel ID"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                autoComplete="off"
                className="form-control shadow-sm"
                id="author"
                placeholder="Search by author name or member ID"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <input
                type="date"
                id="start"
                className={`form-control shadow-sm ${!date && "text-muted"}`}
                name="trip-start"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="btn btn-dark btn-block shadow-sm w-100"
              onClick={formSubmit}
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context);
  return {
    props: { session },
  };
};
